<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="5">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Divisas</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="currencySearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.currency)"
                >
                  Crear Divisa
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.currency"
              :items="currency_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :currencySearch="currencySearch"
              :loading="loadingCurrencyTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.currency)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.currency)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="7">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Tipos de Cambio</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="exchangeRateSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.exchange_rate)"
                >
                  Crear Tipo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.exchange_rate"
              :items="exchangeRate_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :exchangeRateSearch="exchangeRateSearch"
              :loading="loadingExchangeRateTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.exchange_rate)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.exchange_rate)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Currency Form -->
    <v-row justify="center" >
      <v-form ref="createCurrencyForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogCurrency" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Divisa ${createCurrencyForm.name}` : `Crear Divisa` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createCurrencyForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Símbolo"
                      v-model="createCurrencyForm.symbol"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.currency)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.currency)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.currency)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Exchange Rate Form -->
    <v-row justify="center" >
      <v-form ref="createExchangeRateForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogExchangeRate" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Tipo Cambio ${createExchangeRateForm.id}` : `Crear Tipo Cambio` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="4">                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createExchangeRateForm.currency"
                      item-value="id"
                      dense
                      :items="currency_records"
                      item-text="name"
                      label="Divisa"
                      required
                      :rules="createFormRules"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col xs="12" md="4" class="mr-2">
                    <v-menu
                      v-model="exchangeRateDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      required
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          class="py-1"
                          v-model="createExchangeRateForm.date"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="createFormRules"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="createExchangeRateForm.date"
                        @input="exchangeRateDatePicker = false"
                        :rules="createFormRules"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Compra"
                      type="number"
                      min="0"
                      v-model="createExchangeRateForm.purshace"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="4">
                    <v-text-field
                      dense
                      label="Venta"
                      type="number"
                      min="0"
                      v-model="createExchangeRateForm.sale"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.exchange_rate)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.exchange_rate)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.exchange_rate)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- DIALOG DELETE CURRENCY -->
    <v-dialog v-model="dialogDeleteCurrency" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta divisa?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.currency)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.currency)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE EXCHANGE RATE -->
    <v-dialog v-model="dialogDeleteExchangeRate" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar est tipo de cambio?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.exchange_rate)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.exchange_rate)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingCurrencyTable: false,
      loadingExchangeRateTable: false,
      //dialog delete
      dialogDeleteCurrency: false,
      dialogDeleteExchangeRate: false,
      //search
      currencySearch: '',
      exchangeRateSearch: '',
      //records
      currency_records: [],
      exchangeRate_records: [],
      //form
      exchangeRateDatePicker: false,
      btnEdithVisible: false,
      valid: false,
      dialogCurrency: false,
      dialogExchangeRate: false,
      createCurrencyForm: {},
      createExchangeRateForm: {},
      //form rules
      createFormRules: [
        v => !!v || 'El Campo es requerido',
      ],
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.currency) {
        if (!this.$refs.createCurrencyForm.validate()) {
          error = true
        } else {
          form = this.createCurrencyForm
        }
      }

      if (board == this.boards.exchange_rate) {
        if (!this.$refs.createExchangeRateForm.validate()) {
          error = true
        } else {
          this.createExchangeRateForm.purshace = parseFloat(this.createExchangeRateForm.purshace)
          this.createExchangeRateForm.sale = parseFloat(this.createExchangeRateForm.sale)
          form = this.createExchangeRateForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.currency) {
        this.createCurrencyForm.name = item.name
        this.createCurrencyForm.symbol = item.symbol
        this.dialogCurrency = true
      }

      if (board == this.boards.exchange_rate) {
        this.createExchangeRateForm.currency = item.currency
        this.createExchangeRateForm.date = item.date
        this.createExchangeRateForm.purshace = item.purshace
        this.createExchangeRateForm.sale = item.sale
        this.dialogExchangeRate = true
      }
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.currency) this.dialogDeleteCurrency = true
      if (board == this.boards.exchange_rate) this.dialogDeleteExchangeRate = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.currency) this.dialogCurrency = true
      if (board == this.boards.exchange_rate) this.dialogExchangeRate = true
    },
    closeForm(board) {
      if (board == this.boards.currency) {
        this.dialogCurrency = false
        this.$refs.createCurrencyForm.resetValidation()
        this.createCurrencyForm = {}
      }

      if (board == this.boards.exchange_rate) {
        this.dialogExchangeRate = false
        this.$refs.createExchangeRateForm.resetValidation()
        this.createExchangeRateForm = {}
      }

      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.currency) this.dialogDeleteCurrency = false
      if (board == this.boards.exchange_rate) this.dialogDeleteExchangeRate = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.currency) this.loadingCurrencyTable = false
        if (board == this.boards.exchange_rate) this.loadingExchangeRateTable = false
        
        if (board == this.boards.currency) this.currency_records = result.data.data
        if (board == this.boards.exchange_rate) this.exchangeRate_records = result.data.data
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.currency)
    this.getData(this.boards.exchange_rate)
  }
}
</script>