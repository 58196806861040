<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
         TIPOS DE CAMBIO
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <ExchangeRateTable :headers="headers" :boards="boards">

       </ExchangeRateTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import ExchangeRateTable from '@/components/ExchangeRateTable.vue' 
export default {
  name: 'ExchangeRate',
  components: {
    ExchangeRateTable
  },
  data() {
    return {
      headers: {
        currency: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Símbolo',
            align: 'start',
            value: 'symbol',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
        exchange_rate: [
          {
            text: 'Divisa',
            align: 'start',
            value: 'currencyId.name',
          },
          {
            text: 'Fecha',
            align: 'start',
            value: 'date',
          },
          {
            text: 'Compra',
            align: 'start',
            value: 'purshace',
          },
          {
            text: 'Venta',
            align: 'start',
            value: 'sale',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ]
      },
      boards: {
        currency: 'currency',
        exchange_rate: 'exchange_rate'
      }
    }
  },
}
</script>

<style>

</style>